var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        title:
          _vm.title ||
          _vm.$tc("archiveConfirm", _vm.rowData.length, {
            count: _vm.rowData.length
          }),
        "confirm-loading": _vm.loading
      },
      on: { cancel: _vm.handleModalCancel, ok: _vm.handleProjectArchiving }
    },
    [
      _c("SGrid", {
        attrs: {
          "grid-height": 300,
          rowData: _vm.rowData,
          columnDefs: _vm.columnDefs,
          gridContext: _vm.context,
          "grid-name": "project-archive-model"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }