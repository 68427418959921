//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CreatedCellRenderer } from '../grid';

export default {
  components: {
    CreatedCellRenderer
  },
  props: {
    visible: { type: Boolean, default: false },
    rowData: { type: Array, default: () => [] },
    title: { type: String, default: '' },
    columnDefs: {
      type: Array,
      default: () => [
        {
          headerName: 'Name',
          field: 'name',
          resizable: true,
          suppressSizeToFit: true,
          lockPosition: true,
          cellClass: 'locked-col',          
        },
        {
          headerName: 'project.grid.Created',
          field: 'created',
          width: 120,
          resizable: true,
          lockPosition: true,
          cellClass: 'locked-col',
          cellRenderer: 'CreatedCellRenderer'
        }
      ]
    }
  },

  data() {
    return {
      context: { componentParent: this },
      loading: false
    };
  },

  methods: {
    handleModalCancel() {
      this.$emit('cancel');
    },
    handleProjectArchiving() {
      new Promise((resolve, reject) => {
        this.$emit('ok', { resolve, reject });
      })
        .then(() => {})
        .catch(error => console.log('Has an error: ', error))
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
